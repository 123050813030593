// document.addEventListener("turbolinks: load", function () {
//     var options = {
//         data: ["John", "Paul", "George", "Ringo"]
//     };
//     $('*[data-behavior="autocomplete"]').easyAutocomplete(options);
// });


social = null

document.addEventListener("turbolinks:load", function() {
    social = $('#social').DataTable( {
        stateSave: true,
        responsive: true
    } )
    .columns.adjust()
    .responsive.recalc();
  });

  document.addEventListener("turbolinks:before-cache", function() {
    if (social !== null) {
        social.destroy();
        social = null;
    }
} );


// cash = null

// document.addEventListener("turbolinks:load", function() {
//     cash = $('#cash').DataTable({
//             stateSave: true,
//             responsive: true
//         })
//         .columns.adjust()
//         .responsive.recalc();
// });

// document.addEventListener("turbolinks:before-cache", function() {
//     if (cash !== null) {
//         cash.destroy();
//         cash = null;
//     }
// });


employee = null

document.addEventListener("turbolinks:load", function() {
    employee = $('#employee').DataTable({
            stateSave: true,
            responsive: true
        })
        .columns.adjust()
        .responsive.recalc();
});

document.addEventListener("turbolinks:before-cache", function() {
    if (employee !== null) {
        employee.destroy();
        employee = null;
    }
});


dataTable = null

document.addEventListener("turbolinks:load", function() {
    dataTable = $('#example').DataTable( {
        stateSave: true,
        responsive: true
    } )
    .columns.adjust()
    .responsive.recalc();
  });


document.addEventListener("turbolinks:before-cache", function() {
    if (dataTable !== null) {
        dataTable.destroy();
        dataTable = null;
    }
} );

dataTable1 = null
document.addEventListener("turbolinks:load", function() {
    dataTable1 = $('#example1').DataTable( {
        stateSave: true,
        responsive: true
    } )
    .columns.adjust()
    .responsive.recalc();
  });


  document.addEventListener("turbolinks:before-cache", function() {
    if (dataTable1 !== null) {
        dataTable1.destroy();
        dataTable1 = null;
    }
} );

final = null
document.addEventListener("turbolinks:load", function() {
    final = $('#final').DataTable( {
        stateSave: true,
        responsive: true
    } )
    .columns.adjust()
    .responsive.recalc();
  });


  document.addEventListener("turbolinks:before-cache", function() {
    if (final !== null) {
        final.destroy();
        final = null;
    }
} );


audit = null
document.addEventListener("turbolinks:load", function() {
    audit = $('#audit').DataTable( {
        stateSave: true,
        responsive: true
    } )
    .columns.adjust()
    .responsive.recalc();
  });


  document.addEventListener("turbolinks:before-cache", function() {
    if (audit !== null) {
        audit.destroy();
        audit = null;
    }
} );


dm = null
document.addEventListener("turbolinks:load", function() {
    dm = $('#dm').DataTable( {
        stateSave: true,
        responsive: true
    } )
    .columns.adjust()
    .responsive.recalc();
  });


  document.addEventListener("turbolinks:before-cache", function() {
    if (dm !== null) {
        dm.destroy();
        dm = null;
    }
} );